export function getPropertyEndingWith(feature, keySuffix) {
  const key = feature.getKeys().filter((key) => key.endsWith(keySuffix))[0];
  return feature.get(key);
}

export function getPropertyEndingWithExcludingLayer7(feature, keySuffix) {
  const key = feature
    .getKeys()
    .filter((key) => !key.startsWith("7-"))
    .filter((key) => !key.startsWith("osm-"))
    .filter((key) => key.endsWith(keySuffix))[0];
  return feature.get(key);
}

import { Stroke, Style, Circle, Fill } from "ol/style";
import { getPropertyEndingWith } from "./propertyExtraction";
export function getNodeStyleClick(feature) {
  return getNodeStyle(feature, true, false);
}
export function getNodeStyleHover(feature) {
  return getNodeStyle(feature, false, true);
}
export function getNodeStyleNormal(feature) {
  return getNodeStyle(feature, false, false);
}
function getNodeStyle(feature, click, hover) {
  if (feature.getGeometry().getType() === "Point") {
    let radius = 2;
    let strokeColor = "green";
    let fillColor = "white";
    let visible = false;
    if ("yes" === getPropertyEndingWith(feature, "-connector")) {
      radius = 3;
      strokeColor = "grey";
      fillColor = "pink";
      visible = true;
    }
    if (hover) {
      radius = 6;
    }
    if (click) {
      fillColor = "orange";
      radius = 6;
    }
    if (visible) {
      return [
        new Style({
          image: new Circle({
            fill: new Fill({
              color: fillColor,
            }),
            stroke: new Stroke({
              color: strokeColor,
              width: 1,
            }),
            radius: radius,
          }),
        }),
      ];
    }
  }
  return [];
}
